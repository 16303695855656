<template>
  <div>
    <b-row class="card">
      <div class="w-100">
        <h5 class="title-card">
          <i class="fas fa-user"></i> Dados Pessoais
        </h5>
      </div>
      <div class="w-100 p-3">
        <b-form>
          <b-row>
            <b-col lg="5">
              <b-form-group label="Nome do cliente">
                <b-form-input
                :class="{ 'form-group--error': $v.payload.name.$error }"
                v-model="$v.payload.name.$model"
                :state="$v.payload.name.$dirty ?
                !$v.payload.name.$error : null"
                />
                <b-form-invalid-feedback>
                  Preenchimento obrigatório
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col lg="7">
              <b-form-group label="Email">
                <b-form-input
                :class="{ 'form-group--error': $v.payload.email.$error }"
                v-model="$v.payload.email.$model"
                :state="$v.payload.email.$dirty ?
                !$v.payload.email.$error : null"
                />
                <b-form-invalid-feedback>
                  Preenchimento obrigatório
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col lg="4">
              <b-form-group label="Documento (CPF ou CNPJ)">
                <b-form-input
                v-mask="['###.###.###-##', '##.###.###/####-##']"
                :class="{ 'form-group--error': $v.payload.document.$error }"
                v-model="$v.payload.document.$model"
                :state="$v.payload.document.$dirty ?
                !$v.payload.document.$error : null"
                />
                <b-form-invalid-feedback>
                  Preenchimento obrigatório
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col lg="4">
              <b-form-group label="Celular">
                <b-form-input
                v-mask="'(##) # ####-####'"
                :class="{ 'form-group--error': $v.payload.phone_mobile.$error }"
                v-model="$v.payload.phone_mobile.$model"
                :state="$v.payload.phone_mobile.$dirty ?
                !$v.payload.phone_mobile.$error : null"
                />
                <b-form-invalid-feedback>
                  Preenchimento obrigatório
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col lg="4">
              <b-form-group label="Fone Fixo">
                <b-form-input
                v-mask="'(##)####-####'"
                />
              </b-form-group>
           </b-col>
          </b-row>
        </b-form>
      </div>
    </b-row>

    <b-row class="card mt-5">
      <div class="w-100">
        <h5 class="title-card">
          <i class="fas fa-home"></i> Endereço
        </h5>
      </div>
      <div class="w-100 p-3">
        <b-row>
            <b-col lg="3">
              <search-cep @data="setAddress" />
            </b-col>

            <b-col lg="7">
              <b-form-group label="Rua">
                <b-form-input
                :readonly="address_readonly"
                :class="{ 'form-group--error': $v.payload.address.street.$error }"
                v-model="$v.payload.address.street.$model"
                :state="$v.payload.address.street.$dirty ?
                !$v.payload.address.street.$error : null"
                />
                <b-form-invalid-feedback>
                  Preenchimento obrigatório
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col lg="2">
              <b-form-group label="Número">
                <b-form-input
                v-model="payload.address.number"
                :readonly="address_readonly"/>
              </b-form-group>
            </b-col>

            <b-col lg="5">
              <b-form-group label="Bairro">
                <b-form-input
                :readonly="address_readonly"
                :class="{ 'form-group--error': $v.payload.address.neighborhood.$error }"
                v-model="$v.payload.address.neighborhood.$model"
                :state="$v.payload.address.neighborhood.$dirty ?
                !$v.payload.address.neighborhood.$error : null"
                />
                <b-form-invalid-feedback>
                  Preenchimento obrigatório
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col lg="">
              <b-form-group label="Estado">
                <b-form-select
                :class="{ 'form-group--error': $v.payload.address.province.$error }"
                v-model="$v.payload.address.province.$model"
                :state="$v.payload.address.province.$dirty ?
                !$v.payload.address.province.$error : null"
                @change="getCities"
                :options="provinces"/>
                <b-form-invalid-feedback>
                  Preenchimento obrigatório
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col lg="">
              <b-form-group label="Cidade">
                 <b-form-select
                :class="{ 'form-group--error': $v.payload.address.city_id.$error }"
                v-model="$v.payload.address.city_id.$model"
                :state="$v.payload.address.city_id.$dirty ?
                !$v.payload.address.city_id.$error : null"
                :options="cities"/>
                 <!-- <Autocomplete
                  ref="cities"
                  :source="cities"
                  input-class="form-control"
                  :disableInput="!cities.length"
                  resultsValue="id"
                  :initial-display="city_name"
                  :initial-value="2"
                  :placeholder="labelSelectCities"
                  @selected="setCity"
                /> -->
                <b-form-invalid-feedback>
                  Preenchimento obrigatório
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
        </b-row>
        <div class="w-100 text-right mt-5">
          <b-button variant="success" @click="save(false)">Salvar</b-button>
          <b-button variant="info" class="ml-3" @click="save(true)">Salvar e criar pré projeto</b-button>
        </div>
      </div>
    </b-row>
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';
// import Autocomplete from 'vuejs-auto-complete';
import HelperService from '@/Services/HelperService';
import ClientService from '@/Services/ClientService';
import MessagesMixin from '@/mixins/messages-mixin';
import QuestionMixin from '@/mixins/question-mixin';
import ErrorMixin from '@/mixins/error-mixin';

export default {
  props: ['id'],
  mixins: [MessagesMixin, QuestionMixin, ErrorMixin],
  data() {
    return {
      copy_address: false,
      address_installation_readonly: false,
      address_readonly: false,
      provinces: [
        {
          text: 'Selecione',
          value: '',
        },
      ],
      labelSelectCities: 'Selecione primeiro o estado',
      cities: [],
      load: false,
      city_name: 'Teste',
      cities_installation: [],
      payload: {
        name: '',
        email: '',
        document: '',
        phone_mobile: '',
        agent_id: '',
        phone: '',
        address: {
          zip_code: '',
          street: '',
          number: '',
          neighborhood: '',
          province: '',
          city_id: '',
          address_type: 'address',
        },
      },
    };
  },
  validations: {
    payload: {
      name: { required },
      email: { required, email },
      document: { required },
      phone_mobile: { required },
      address: {
        street: { required },
        neighborhood: { required },
        province: { required },
        city_id: { required },
      },
    },
  },
  methods: {
    async getProvinces() {
      const { data } = await HelperService.getProvinces();
      data.forEach((item) => {
        this.provinces.push({
          text: item.name,
          value: item.id,
        });
      });
    },

    async getClient() {
      const { data } = await ClientService.get(this.id);
      this.payload.id = data.id;
      this.payload.name = data.name;
      this.payload.email = data.email;
      this.payload.document = data.document;
      this.payload.phone_mobile = data.phone_mobile;
      this.payload.phone = data.phone;

      this.payload.address.province = data.addresses[0].city.province_id;
      this.payload.address.city_id = data.addresses[0].city.id;
      this.getCities();
      this.city_name = data.addresses[0].city.name;
      this.payload.address.id = data.addresses[0].id;
      this.payload.address.zip_code = data.addresses[0].zip_code;
      this.payload.address.street = data.addresses[0].street;
      this.payload.address.number = data.addresses[0].number;
      this.payload.address.neighborhood = data.addresses[0].neighborhood;
    },

    setAddress(data) {
      this.payload.address.zip_code = data.cep;
      this.payload.address.street = data.logradouro;
      this.payload.address.province = data.id_estado;
      this.payload.address.neighborhood = data.bairro;
      this.getCities();
      setTimeout(() => {
        // eslint-disable-next-line max-len
        this.payload.address.city_id = this.cities.find((item) => HelperService.removeAcentos(item.text) === HelperService.removeAcentos(data.localidade)).value;
      }, 1000);
    },

    async getCities() {
      const params = {
        province_id: this.payload.address.province,
      };

      const { data } = await HelperService.getCities(params);
      this.cities = data.map((item) => ({
        text: item.name,
        value: item.id,
      }));
    },

    setCity(city) {
      this.payload.address.city_id = city.selectedObject.id;
    },

    async save(createProject) {
      let { data } = {};
      this.$v.payload.$touch();
      if (!this.$v.payload.$invalid) {
        try {
          if (this.id) {
            data = await ClientService.edit(this.payload);
            this.messageSuccess(`O cliente ${data.name} foi atualizado com sucesso`);
          } else {
            data = await ClientService.save(this.payload);
            this.messageSuccess(`O cliente ${data.name} foi cadastrado com sucesso`);
          }

          if (createProject) {
            this.$router.push({ name: 'project-create-with-client', params: { client_id: data.id } });
          } else {
            this.$router.push({ name: 'clients' });
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
  },
  created() {
    this.getProvinces();
    if (localStorage.getItem('permission') !== 'admin') {
      const user = JSON.parse(localStorage.getItem('user'));
      this.payload.agent_id = user.agent.id;
    }

    if (this.id) this.getClient();
  },
};
</script>
